export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'name',
      label: 'user.role-name'
    },
    {
      prop: 'description',
      label: 'user.role-description'
    },

    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 180
    },
    {
      label: 'common.app-operation',
      slotName: 'handler',
      width: 280
    }
  ],
  showIndexColumn: true
}
